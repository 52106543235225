import useScrollToTop from 'hooks/useScrollToTop';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Blogs } from 'utils/BlogUtils';
import { BASE_APIURL, sanitizeTitle } from 'utils/helpers';
import '../views/Blogs/Blogs'
import LoaderSpiner from 'Components/LoaderSpiner';

// const fetchBlogs = (blogtitle) => {
//   const Blog = Blogs.find((blog) => sanitizeTitle(blog.title) === blogtitle)
//   return Blog
// }

const BlogDetail = () => {

  //custom hook to scroll to top 
  useScrollToTop();

  // const { blogtitle } = useParams();
  const { fileName } = useParams()//31d9e408-f58a-465e-aa51-18a0047db6d9.html
  console.log("key", fileName)
  console.log('blogkey', fileName)
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("")
  // const Blogdata = fetchBlogs(blogtitle)


  useEffect(() => {
    const fetchBlogs = async () => {
      console.log('inn')
      try {
        // Fetching blogs from backend API
        const response = await fetch(`${BASE_APIURL}/blog/html-files/${fileName}`);

        if (!response.ok) {
          throw new Error('Failed to fetch blogs');
        }

        const foundBlog = await response.json();
        console.log('hshas', foundBlog)
        // console.log("all",allBlogs)
        // // setBlogs(data.htmlFiles); // The array of HTML files
        // const foundBlog = allBlogs.htmlFiles.find(blog => blog.key === key);
        // console.log('found',foundBlog)
        if (foundBlog) {
          setBlogData(foundBlog)
        }
        else {
          console.log("Blog Not found")
          setError("Blog not found")
          setLoading(false)
        }

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  console.log(blogData)

  if (loading) {
    return <div><LoaderSpiner /></div>
  }

  if (!blogData) {
    return <p>Blog content not found.</p>;
  }
  return (

    <div style={{ width: "80%", margin: "0 auto", textAlign: "center" }} className="blogs-container">
      <div
        // key={blog.key}
        style={{
          marginTop: "20px",
          padding: "20px",
          textAlign: "left",
          borderBottom: "1px solid #ccc",
          maxWidth: "1000px",
          margin: "20px auto",
        }}
        className="custom-blog-content"
      >
        <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
      </div>

    </div>
  )
}
export default BlogDetail;