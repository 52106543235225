import { Link, NavLink } from "react-router-dom";
import styles from "./Footer.module.css";
import logo from 'assets/Newlogo.png';
import locationIcon from "assets/locationIcon.png";

const Footer = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <div>
                    <img src={logo} alt="ProvaanTech Logo" className={styles.logoimg} />
                    <h1 className={styles.subHead}>Provaantech</h1>
                </div>
            </div>
            <div className={styles.Navbar}>
                <div className={styles.miniNavbar}>
                    <NavLink to="/" className={({ isActive }) => isActive ? styles.active : undefined}>Home</NavLink>
                    <NavLink to="/services/offerings/Outsourcing-And-Talent" className={({ isActive }) => isActive ? styles.active : undefined}>Services</NavLink>
                    <NavLink to="/services/industries/Automotive-Aerospace" className={({ isActive }) => isActive ? styles.active : undefined}>Industries</NavLink>
                    {/* <NavLink to="#" className={({ isActive }) => isActive ? styles.active : undefined}>Clients</NavLink> */}
                    <NavLink to="/insights" className={({ isActive }) => isActive ? styles.active : undefined}>Blog</NavLink>
                    <a href="#contact" className={({ isActive }) => isActive ? styles.active : undefined}>Contact</a>
                </div>
            </div>
            <div className={styles.addressDiv}>
                <div className={styles.heading}>
                    <h1 className={styles.subHeading}>Our Offices</h1>
                </div>
                <div className={styles.address}>
                    <div className={`${styles.addressContainer}  opacity-25`}>
                        <div className={styles.locationIcon}>
                            <img src={locationIcon} alt="locationIcon" />
                        </div>
                        <div className={styles.textContainer}>
                            <h1>Patna</h1>
                            <p>Prabha Kunj Motichowk khagaul Patna Bihar – 801105.</p>
                        </div>
                    </div>
                    <div className={styles.addressContainer}>
                        <div className={styles.locationIcon}>
                            <img src={locationIcon} alt="locationIcon" />
                        </div>
                        <div className={styles.textContainer}>
                            <h1>Pune</h1>
                            <p>{" \t"}Office No:1219, Floor No:-12, Geras Imperium raise, opposite to Wipro circle, Hinjawadi Phase 2, Pune, Maharashtra - 411057
                              
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.addressContainer}  opacity-25`}>
                        <div className={styles.locationIcon}>
                            <img src={locationIcon} alt="locationIcon" />
                        </div>
                        <div className={styles.textContainer}>
                            <h1>USA</h1>
                            <p>1945 McGraw ave , apt 5H. Bronx , NY, 10462
                                Contact: +1 (917)391-6482</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.socialMedia}>
                <Link>Facebook</Link>
                <a href="https://www.linkedin.com/company/101485468/?trk=public_profile_follow" target="_blank">LinkedIn</a>
                <a href="https://www.instagram.com/provaantech/profilecard/?igsh=MnA0dDcxdHo0MDU5" target="_blank">Instagram</a>
                <Link>Twitter</Link>
                <Link>Dribbble</Link>
            </div>
            <div className={styles.footerContent}>
                <p>
                    ©2009-2024 Provaantech Technologies Pvt. Ltd. All Rights Reserved
                </p>
            </div>
        </div>
    );
};

export default Footer;
