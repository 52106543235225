import React from 'react';

const LoaderSpiner = () => {
  const loaderContainerStyle = {
   height:'30rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const loaderStyle = {
    width: '70px',
    margin:'3rem auto',
    aspectRatio: '1',
    borderRadius: '50%',
    border: '8px solid #2e2e2e',
    animation: `
      l20-1 0.8s infinite linear alternate,
      l20-2 1.6s infinite linear`,
  };

  return (
    <div style={loaderContainerStyle}>
      <div style={loaderStyle}></div>
      <style>
        {`
          @keyframes l20-1 {
            0%    {clip-path: polygon(50% 50%,0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%)}
            12.5% {clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%)}
            25%   {clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%)}
            50%   {clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)}
            62.5% {clip-path: polygon(50% 50%,100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%)}
            75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%)}
            100%  {clip-path: polygon(50% 50%,50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%)}
          }
          @keyframes l20-2 { 
            0%    {transform: scaleY(1) rotate(0deg);}
            49.99%{transform: scaleY(1) rotate(135deg);}
            50%   {transform: scaleY(-1) rotate(0deg);}
            100%  {transform: scaleY(-1) rotate(-135deg);}
          }
        `}
      </style>
    </div>
  );
};

export default LoaderSpiner;
